<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { format } from 'date-fns'
import { capitalize, get, isNil } from 'lodash'
import { onMounted, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { rails_url } from '@/modules/shared/utils/rails'
import { unflattenKey } from '@/modules/shared/utils/v-table'
import {
  ActionItem,
  ActionsGroup,
  ActionsMenu,
  IconFileX,
  IconLinkExternal,
  IconThumbsUp,
  IconTrash,
  IconGlasses02,
  IconFileCheck,
  RouterLinkBack,
  VAvatar,
  VButton,
  VButtonInvisible,
  VIcon,
  VMailTo,
  VSection,
  VTable,
} from '@/modules/shared/components'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { VModal, VTextField } from '@/modules/shared/components'
import { useModal } from '@/modules/shared/composables/use-modal'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'
import IndividualsNav from '../components/individuals-nav.vue'
import { useInvestingInvestorStore } from '../stores/investor-store'
import { addressColumns } from '../config/columns'

///////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////

const { t } = useExtendedI18n()

///////////////////////////////////////////////////////////////////////////////

const route = useRoute()
const router = useRouter()

const authStore = useAuthStore()
const workspaceStore = useWorkspaceStore()
const current_user = computed(() => authStore.current_user)
const cid = computed(() => `individual:${current_user.value.investor_id}`)
const investingInvestorStore = useInvestingInvestorStore()

const isAdmin = computed(() => current_user.value.role !== 'investor')

const skeleton = ref(true)
const selectedStatus = ref('active')

const deleteAction = async (item) => {
  if (!window.confirm('Are you sure?')) return
  await investingInvestorStore.removeIndividual(item.id)
  await investingInvestorStore.fetchIndividuals('all')
}

const revokeAccessAction = async (item) => {
  if (!window.confirm('Are you sure?')) return
  await investingInvestorStore.revokeAccessIndividual(item.id, 'all')
}

const activateAccessAction = async (item) => {
  if (!window.confirm('Are you sure?')) return
  await investingInvestorStore.activateAccessIndividual(item.id, 'all')
}

const sendRegistrationInvitation = async (item) => {
  await investingInvestorStore.sendRegistrationInvitationToIndividual(item.id)
  alert(`${item.name} has been sent a link to register`)
}

const can_view_investor_profile = (item) => {
  // Backend is already handling the permissions on which investors that other investors can see. So in this case, the investor should be able to access the investors that being displayed here
  // if (isAdmin.value) return true
  // if (current_user.value.investor_id === item.id) return true
  // if (!workspaceStore.current.options.hide_investors_from_investors) return true
  return true
}

const getAmlStatus = (individual) => {
  let status = get(individual, 'aml.is_whitelisted') ? 'whitelisted' : get(individual, 'aml.match_status')

  switch (status) {
    case 'false_positive':
    case 'no_match':
    case 'true_positive_reject':
    case 'unknown':
    case 'whitelisted':
      return 'pass'
    case 'potential_match':
      return 'potential_match'
    case 'true_positive':
    case 'true_positive_approve':
      return 'match'
  }

  return 'default'
}

///////////////////////////////////////////////////////////////////////////////
// Add Individual
///////////////////////////////////////////////////////////////////////////////
const individualFormModal = useModal()

const individual_initial_state = {
  id: null,
  first_name: null,
  last_name: null,
  email: null,
}

const individual_rules = {
  first_name: { required },
  last_name: { required },
  email: { required, email },
}
const individual_form = ref({ ...individual_initial_state })
const vi$ = useVuelidate(individual_rules, individual_form, { $lazy: true })

const addIndividual = () => {
  vi$.value.$reset()
  individual_form.value = { ...individual_initial_state }
  individualFormModal.open()
}

const saveIndividual = async () => {
  const valid = await vi$.value.$validate()
  if (!valid) return

  await investingInvestorStore.addIndividual(individual_form.value, 'all')
  individualFormModal.close()
}

///////////////////////////////////////////////////////////////////////////////
// Table
///////////////////////////////////////////////////////////////////////////////
const individuals = computed(() => {
  if (isAdmin.value) {
    return investingInvestorStore.individuals.filter(
      (individual) =>
        individual?.status?.find((a) => a.group_id === workspaceStore.current.id)?.status?.toLowerCase() ===
        selectedStatus.value,
    )
  }

  return investingInvestorStore.individuals
})

const canViewColumn = (column) => {
  if (column.key === 'actions') return true
  if (column.key === 'aml_status') return workspaceStore.current.options.is_aml_enabled
  if (authStore.is_site_or_group_admin) return true
  if (['email', 'name'].includes(column.key)) return true

  return false
}

const columns = computed(() => {
  const cols = [
    {
      key: 'name',
      name: capitalize(t('shared.name', 0)),
      type: 'string',
      align: 'left',
      is_visible: true,
      fixed: true,
    },
    {
      key: 'investing_name',
      name: capitalize(t('shared.investing name')),
      type: 'string',
      align: 'left',
      is_visible: !isAdmin.value,
    },
    {
      key: 'aml.match_status',
      name: capitalize(t('shared.AML status')),
      type: 'string',
      align: 'center',
      is_visible: true,
    },
    {
      key: 'subgroups',
      name: capitalize(t('shared.subgroup')),
      type: 'string',
      align: 'left',
      is_visible: false,
    },
    {
      key: 'accredited',
      name: capitalize(t('shared.accredited')),
      type: 'string',
      align: 'left',
      is_visible: false,
    },
    {
      key: 'email',
      name: capitalize(t('shared.email')),
      type: 'string',
      align: 'left',
      is_visible: true,
    },
    {
      key: 'last_login',
      name: capitalize(t('shared.last login at')),
      type: 'date',
      align: 'left',
      is_visible: true,
    },
    {
      key: 'created_at',
      name: capitalize(t('shared.date added')),
      type: 'date',
      align: 'left',
      is_visible: false,
    },
    {
      key: 'membership_currency',
      name: capitalize(t('shared.preferred currency')),
      type: 'string',
      align: 'left',
      is_visible: false,
    },
    ...addressColumns(),
    {
      key: 'type',
      name: capitalize(t('shared.type')),
      type: 'string',
      align: 'left',
      is_visible: false,
    },
    {
      key: 'tax_id',
      name: t('shared.SSN / Tax ID'),
      type: 'string',
      align: 'left',
      is_visible: false,
    },
    {
      key: 'status',
      name: capitalize(t('shared.status')),
      type: 'string',
      align: 'left',
      is_visible: false,
    },
    {
      key: 'actions',
      name: '',
      type: 'actions',
      align: 'right',
      is_visible: isAdmin.value,
    },
  ]
  return cols.filter((column) => canViewColumn(column))
})

const getStatus = (item) => {
  const statuses = unflattenKey(item, 'status')
  return statuses.find((status) => status.group_id === workspaceStore.current.id)?.status
}

onMounted(async () => {
  await workspaceStore.fetchCurrent()

  if (isAdmin.value) {
    await investingInvestorStore.fetchIndividuals('all')
  } else {
    await Promise.all([investingInvestorStore.fetchIndividuals(cid.value)])
  }
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-8 mb-6 flex items-center justify-between">
      <div>
        <RouterLinkBack class="text-lg" :to="{ name: 'dashboard' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>

    <VSection :label="isAdmin ? null : 'Individuals'">
      <IndividualsNav v-if="isAdmin" />
      <div class="mb-3 flex items-center justify-between" v-if="isAdmin">
        <div class="flex items-center space-x-1.5">
          <VButton :active="selectedStatus === 'active'" class="w-32" :click="() => (selectedStatus = 'active')">
            <span>{{ capitalize(t('shared.active')) }}</span>
          </VButton>
          <VButton :active="selectedStatus === 'pending'" class="w-32" :click="() => (selectedStatus = 'pending')">
            <span>{{ capitalize(t('shared.pending')) }}</span>
          </VButton>
          <VButton :active="selectedStatus === 'inactive'" class="w-32" :click="() => (selectedStatus = 'inactive')">
            <span>{{ capitalize(t('shared.inactive')) }}</span>
          </VButton>
        </div>
        <div v-if="authStore.is_site_or_group_admin" class="flex items-center space-x-1.5">
          <VButton :click="addIndividual" size="md" variant="v-blue">
            <div class="mr-1 flex items-center space-x-2">
              <div><VIcon name="plus" /></div>
              <div>Add Individual</div>
            </div>
          </VButton>
          <RouterLink :to="{ name: 'investing.individuals.import' }">
            <VButton>
              <div class="mr-1 flex items-center space-x-2">
                <div><VIcon name="corner-right-down" /></div>
                <div>Import</div>
              </div>
            </VButton>
          </RouterLink>
        </div>
      </div>

      <VTable
        :name="`individuals-${route.params.slug}`"
        :columns="columns"
        :items="individuals"
        :skeleton="skeleton"
        :slots="['actions', 'aml.match_status', 'email', 'last_login', 'name', 'subgroups']"
      >
        <template #name="{ item }">
          <RouterLink
            v-if="can_view_investor_profile(item)"
            class="flex cursor-pointer items-center space-x-3"
            :to="{
              name: 'investing.individual-overview',
              params: { individual_id: item.id },
            }"
          >
            <VAvatar :image="item.profile_picture" :initials="item.initials" size="sm" />
            <div class="hyperlink -mt-0.5">
              {{ item.name }}
            </div>
          </RouterLink>
          <div class="flex items-center space-x-3" v-else>
            <VAvatar :image="item.profile_picture" :initials="item.initials" size="sm" />
            <div class="-mt-0.5">
              {{ item.name }}
            </div>
          </div>
        </template>
        <template #aml.match_status="{ item }">
          <template v-if="getAmlStatus(item) === 'default'">
            <span class="text-gray-300">
              <VIcon name="hourglass-01" />
            </span>
          </template>
          <template v-else>
            <a class="hyperlink" :href="get(item, 'aml.share_url')" target="_blank">
              <template v-if="getAmlStatus(item) === 'pass'">
                <span class="text-[rgb(64,146,126)]">
                  <VIcon name="shield-tick" class="block h-[18px] w-[18px]" />
                </span>
              </template>
              <template v-if="getAmlStatus(item) === 'potential_match'">
                <span class="text-orange-400">
                  <VIcon name="flag-02" />
                </span>
              </template>
              <template v-if="getAmlStatus(item) === 'match'">
                <span class="text-red-600">
                  <VIcon name="slash-circle-01" />
                </span>
              </template>
            </a>
          </template>
        </template>
        <template #email="{ item }">
          <VMailTo :email="item.email" />
        </template>
        <template #last_login="{ item }">
          {{ item.last_login ? format(new Date(item.last_login), "EEEE, MMMM d, y 'at' h:mma") : 'Never' }}
        </template>
        <template #subgroups="{ item }">
          <span v-for="(subgroup, index) in unflattenKey(item, 'subgroup')" :key="subgroup.id" style="display: block">
            <a v-if="isAdmin" class="hyperlink" :href="`${rails_url()}/chapters/${subgroup.id}`"
              >{{ subgroup.name }}
            </a>
            <span class="text-gray-900" v-else>{{ subgroup.name }}</span>
          </span>
        </template>
        <template #actions="{ item }">
          <div class="flex items-center justify-end space-x-1.5">
            <ActionsMenu v-if="authStore.is_site_or_group_admin">
              <ActionsGroup>
                <ActionItem
                  @click="() => router.push({ name: 'investing.individual.edit', params: { individual_id: item.id } })"
                  :icon="IconLinkExternal"
                  :text="capitalize(t('shared.edit profile'))"
                  tag="a"
                />
                <ActionItem
                  v-if="
                    authStore.is_site_or_group_admin && (getStatus(item) == 'Active' || getStatus(item) == 'Pending')
                  "
                  :icon="IconFileX"
                  :text="capitalize(t('shared.revoke access'))"
                  @click="revokeAccessAction(item)"
                />
                <ActionItem
                  v-if="getStatus(item) !== 'Active' && authStore.is_site_or_group_admin"
                  :icon="IconThumbsUp"
                  :text="capitalize(t('shared.activate'))"
                  @click="activateAccessAction(item)"
                />
                <ActionItem
                  v-if="authStore.is_site_admin"
                  :icon="IconGlasses02"
                  :text="capitalize(t('shared.view as'))"
                  @click="authStore.viewAs(get(item, 'user.id'))"
                />
                <ActionItem
                  v-if="authStore.is_site_admin"
                  :icon="IconLinkExternal"
                  tag="a"
                  :text="capitalize(t('shared.merge'))"
                  :href="`${rails_url()}/investors/${get(item, 'id')}/merge`"
                />

                <ActionItem
                  v-if="authStore.is_site_admin && !unflattenKey(item, 'user')"
                  :icon="IconFileCheck"
                  tag="a"
                  :text="capitalize(t('shared.resend invitation email'))"
                  @click="sendRegistrationInvitation(item)"
                />
              </ActionsGroup>
              <ActionsGroup>
                <ActionItem
                  v-if="authStore.is_site_or_group_admin"
                  :icon="IconTrash"
                  :text="capitalize(t('shared.delete'))"
                  @click="() => deleteAction(item)"
                />
              </ActionsGroup>
            </ActionsMenu>
          </div>
        </template>
      </VTable>
    </VSection>
    <VModal :modalStore="individualFormModal">
      <template #main>
        <VSection label="Add an Individual">
          <div class="space-y-3">
            <VTextField
              v-model="individual_form.first_name"
              :label="capitalize(t('shared.first name'))"
              property="first_name"
              :v$="vi$"
            />
            <VTextField
              v-model="individual_form.last_name"
              :label="capitalize(t('shared.last name'))"
              property="last_name"
              :v$="vi$"
            />
            <VTextField
              v-model="individual_form.email"
              :label="capitalize(t('shared.email'))"
              property="email"
              type="email"
              :v$="vi$"
            />
          </div>
        </VSection>
      </template>
      <template #footer>
        <VButton :click="saveIndividual" variant="primary" size="lg" class="w-full">Add Individual</VButton>
      </template>
    </VModal>
  </TheLayout>
</template>
