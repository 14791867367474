<script setup lang="ts">
import { computed } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { rails_url } from '@/modules/shared/utils/rails'
import { VProgressBar, VSkeletonBar } from '@/modules/shared/components'
import { Deal } from '../stores/deal-store'

const { n } = useExtendedI18n()
const authStore = useAuthStore()
const props = defineProps<{
  deal: Deal | any
  skeleton: boolean
}>()

const bar_width = computed(() => {
  const capital_goal = parseInt(props.deal.capital_goal.amount)
  const committed = parseInt(props.deal.committed.amount)
  if (capital_goal === 0) return '100%'

  return `${(committed / capital_goal) * 100}%`
})
</script>

<template>
  <div
    class="min-w-[250px] max-w-[250px] rounded-lg border border-gray-300 bg-white p-2 shadow-sm sm:min-w-[400px] sm:max-w-[400px] sm:p-6"
  >
    <div class="mb-4 flex gap-3">
      <VSkeletonBar v-if="skeleton" />
      <template v-else>
        <img
          :src="deal.company.logo"
          class="h-[35px] w-[35px] object-contain sm:h-[50px] sm:w-[50px]"
          alt=""
          v-if="deal.company.logo"
        />
        <div class="h-[35px] w-[35px] object-contain sm:h-[50px] sm:w-[50px]" v-else></div>
        <div class="flex-grow">
          <div class="text-base font-extrabold sm:text-lg">{{ deal.company.name }}</div>
          <div class="text-xs sm:text-sm">{{ deal.investment_type }}</div>
        </div>
        <a
          :href="`${rails_url()}/companies/${deal.company.id}`"
          class="text-xs font-extrabold text-[#3D8A9D] underline sm:text-sm"
          >View Details</a
        >
      </template>
    </div>
    <div class="mb-1 h-[100px] overflow-y-hidden text-sm">
      <div class="flex flex-col gap-3" v-if="skeleton">
        <VSkeletonBar />
        <VSkeletonBar />
        <VSkeletonBar />
        <VSkeletonBar />
        <VSkeletonBar />
      </div>
      <template v-else>{{ deal.company.elevator_pitch }}</template>
    </div>
    <div v-if="authStore.current_user.investor_id" class="mb-0.5 text-xs sm:text-base">
      <VSkeletonBar v-if="skeleton" />
      <template v-else>
        <span class="ml-1">My commitment </span>
        <span>{{ n(deal.investor_commitment, 'currency') }}</span>
      </template>
    </div>
    <div class="relative h-[8px] w-full overflow-hidden rounded-full">
      <VSkeletonBar v-if="skeleton" />
      <template v-else>
        <VProgressBar :progress="parseInt(deal.committed.amount) / parseInt(deal.capital_goal.amount)" />

        <div class="h-full w-full bg-[#3D8A9D] bg-opacity-30" v-if="parseInt(deal.capital_goal.amount) !== 0"></div>
        <div class="absolute left-0 top-0 h-[8px] max-w-full bg-[#3D8A9D]" :style="{ width: bar_width }"></div>
      </template>
    </div>
    <div class="mb-0.5 text-right text-sm font-extrabold sm:text-base">
      <VSkeletonBar v-if="skeleton" />
      <template v-else>
        <span>{{ n(deal.committed, 'currency') }}</span>
        <span class="ml-1 text-gray-400" v-if="parseInt(deal.capital_goal.amount) !== 0"
          >of {{ n(deal.capital_goal, 'currency') }}</span
        >
        <span class="ml-1 text-gray-400" v-else>committed</span>
      </template>
    </div>
  </div>
</template>
